import axios from 'axios';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

const withProtection = (WrapperComponent) => {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                redirect: false
            };
        }
        componentWillMount() {
            if(localStorage.getItem('token') === false || localStorage.getItem('token') === null) {
                this.setState({
                    redirect: true
                })

            }
            else{
                axios.post(`https://worksheetsapi.liamcrest.com/api/verify `, null, { headers: {  Authorization: localStorage.getItem('token') } }) 
                .then(res => {
                      if(res.data.status === false || res.data.data.userData.role_id != '1'){
                        this.setState({
                            redirect: true
                        })      
                      }
                  })
            }

        }
        render() {
            if (this.state.redirect === true) {
                return <Redirect to='/AdminLogin' />
            }
            return (
                <WrapperComponent  {...this.props} />
            )


        }
    }

}

export default withProtection