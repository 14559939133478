import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import AdminLogin from './AdminLogin';
import AdminPanel from './AdminPanel';
import AdminPanelSubCategory from './AdminPanelSubCategory';
import AdminPanelCategory from './AdminPanelCategory';
import Main from './Main';
import AddWorkbook from './AddWorkbook';
  
  const Routes = () => {
      return (
          <Router>
           <Route exact path='/' component={Main} />
           <Route path='/AdminLogin' component={AdminLogin} />
           <Route path='/AdminPanel' component={AdminPanel} />
           <Route path='/AdminPanelCategory' component={AdminPanelCategory} />
           <Route path='/AdminPanelSubCategory' component={AdminPanelSubCategory} />
           <Route path='/AdminWorkbook' component={AddWorkbook} />
          </Router>
      )
  }
  
  export default Routes
  