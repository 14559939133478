import React, { useRef } from 'react';
import { makeStyles, Button } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    panel_input_file: {
        display: 'none'
    },
    choose_btn: {
        backgroundColor: 'white',
    },
    url: {
       color: 'white',
       fontSize: '20px',
       maxWidth: '300px',
       wordWrap: "break-word",

    },
    both: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
}))

const PanelInput = (props) => {
    const classes = useStyles(props)
    const inputRef = useRef(null)
    const handleClick = () => {
        inputRef.current.click()
    }
    return (
        <div className={classes.both}>
        <p className={classes.url}>{props.url}</p>
        <Button variant='contained' size='large' className={classes.choose_btn} onClick={handleClick}>
            Choose File
            <input className={classes.panel_input_file} name={props.name} ref={inputRef} type='file' placeholder={props.placeholder} value={props.value} onChange={props.onChange} accept={props.accept} />
        </Button>
        </div>

    )
}

export default PanelInput