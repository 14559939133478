import React from 'react'
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    footer_head: {
        width: '130px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',

    },
    footer_head_p: {
        fontWeight: '800',
        overflow: "hidden",
        whiteSpace: "nowrap",
    }
}))

const FooterUl = (props) => {
    const classes = useStyles()
    return (
        <div className={classes.footer_head}>
            <img style={{margin: '0% 10% 0% 0%'}} src='/Footer/Asset 47.png' alt='img' />
            <p className={classes.footer_head_p}>
                {props.value}
            </p>
        </div>
    )
}

export default FooterUl
