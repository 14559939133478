import React from 'react';
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    services: {
        padding: '5% 0%',
        backgroundImage: `url('/Services/Asset 13.png')`,
        backgroundPosition: 'center',
        backgroundRepeat: ' no-repeat',
        backgroundSize: 'cover',
    },
    service_card: {
        margin: '1% 3%',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
            transform: 'scale(1.2)',
            transition: "all 0.3s ease-in-out",
        }  
    },
    service_card_img: {
        height: '100px',
        width: '100px'
    },
    service_card_content: {
        width: '100px',
    },
    services_cards: {
        padding: '2% 10%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexWrap: 'wrap'
    }

}))

const Services = (props) => {
    const classes = useStyles(props)
    return (
        <div className={classes.services}>
         <h1 className='font-face-gm'> Why You Should Choose Liam Crest Education </h1>
         <div className={classes.services_cards}>
         <ServiceCard key={1} src='/Services/Asset 14.png' content='DISTANCE LEARING' />
         <ServiceCard key={2} src='/Services/Asset 15.png' content='EDUCATION FOR ALL' />
         <ServiceCard key={3} src='/Services/Asset 16.png' content='QUESTION AND ANSWER' />
         <ServiceCard key={4} src='/Services/Asset 17.png' content='E-LEARNING' />
         <ServiceCard key={5} src='/Services/Asset 18.png' content='RESPONSIVE' />
         <ServiceCard key={6} src='/Services/Asset 19.png' content='MOBILE LEARNING' />
         <ServiceCard key={7} src='/Services/Asset 20.png' content='QUIZ' />
         <ServiceCard key={8} src='/Services/Asset 21.png' content='SELF STUDY' />
         </div>
        </div>
    )
}

export default Services


const ServiceCard = (props) => {
    const classes = useStyles(props)
    return (
        <div className={classes.service_card}>
            <img className={classes.service_card_img} src={props.src} alt='image' />
             <p className={classes.service_card_content}>{props.content}</p>
        </div>
    )
}
