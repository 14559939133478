import React from 'react';
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({

  box: {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    '&::before': {
      content: '"\f13a"',
      position: "absolute",
      top: "0",
      right: "0",
      width: "20%",
      height: "100%",
      textAlign: "center",
      fontSize: "28px",
      lineHeight: "45px",
      color: "rgba(255, 255, 255, 0.5)",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      pointerEvents: "none",
    },
    '&:hover::before': {
      color: "rgba(255, 255, 255, 0.6)",
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  },

  select: {
    backgroundColor: "white",
    color: "black",
    padding: "12px",
    width: "350px",
    border: "none",
    fontSize: "20px",
    boxShadow: "0 5px 25px rgba(0, 0, 0, 0.2)",
    appearance: "none",
    backgroundImage: `url('/AdminPanelCategory/Asset 5.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPositionX: '100%',
    outline: "none",
    borderRadius: '5px',
    border: '2px solid #03375e',
    ['@media(max-width: 500px)']: {
      width: "250px",
    },
  },

}))

const DropDownInput = (props) => {
  const classes = useStyles(props)
  return (
    <div className={classes.box}>
      <select className={classes.select} name={props.name} placeholder={props.placeholder} onChange={props.handleSelect} value={props.value}>
        {props.children}
      </select>
    </div>
  )
}

export default DropDownInput
