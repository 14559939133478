import React from 'react';
import { makeStyles } from '@material-ui/core'
import AddButton from './AddButton';
const useStyles = makeStyles(() => ({
workbook_card: {
    margin: '2% 1%',
    backgroundImage: `url('/AdminPanel/Asset 5.png')`,
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    height: '150px',
    width: '160px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
},
workbook_card_text: {
position: 'absolute',
width: '120px',
top: '0',
left: '125px',
fontWeight: '700',
fontFamily: `'Montserrat', sans-serif`,
},
}))

const WorkbookCard = (props) => {
    const classes = useStyles(props)
    return (
        <div className={classes.workbook_card}>
         <p className={classes.workbook_card_text}>{props.text}</p>
         <AddButton onClick={props.onClick} active={props.active}/>
        </div>
    )
}

export default WorkbookCard
