import React from 'react';
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
filtered_option: {
    color: 'white',
    width: '100%',
    fontSize: '120%',
    margin: '1% 0px',
    padding: '2% 1%',
    textAlign: 'left',
    '&:hover': {
        backgroundColor: 'rgba(255,255,255, 0.5)'
    }
}
}))

const FilteredOption = (props) => {
    const classes = useStyles(props)
    return (
        <p className={classes.filtered_option} onClick={props.onClick}>
           {props.option} 
        </p>
    )
}

export default FilteredOption