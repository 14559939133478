import React from 'react'
import '../Sass/Header.scss'
import SocialIcon from './SocialIcon'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube} from 'react-icons/fa'

const SmallHeader = (props) => {
    return (
        <>
        <div className='small_header'>
        <SocialIcon key={1} href='https://www.youtube.com/channel/UCVYVnbFIbWQ4a3MfPyMhQHw' >
            <FaYoutube color='#ffb210' size='1.3rem' />
        </SocialIcon>
        <SocialIcon key={2} href='https://www.linkedin.com/company/liamcrest' >
            <FaLinkedinIn color='#ffb210' size='1.3rem' />
        </SocialIcon>
        <SocialIcon key={3} href='https://www.instagram.com/liam.crest.corp' >
            <FaInstagram color='#ffb210' size='1.3rem' />
        </SocialIcon>
        <SocialIcon key={4}  href='https://twitter.com/LiamCrestCorp' >
            <FaTwitter color='#ffb210' size='1.3rem' />
        </SocialIcon>
        <SocialIcon key={5}  href='https://www.facebook.com/liamcrestcorp' >
            <FaFacebookF color='#ffb210' size='1.3rem' />
        </SocialIcon>
        </div>
        </>
    )
}

export default SmallHeader