import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core'
import WorkbookCard from './WorkbookCard'
import { Redirect } from 'react-router';
const useStyles = makeStyles(() => ({
    side_panel_modal: {
        backgroundColor: 'rgba(255, 255, 255, .3)',
        padding: '5% 2%',
        height: '70vh',
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        ['@media (max-width:500px)']: {
            width: '80%',
            height: '80vh',
        }
    },
    side_panel_heading: {
        color: '#03375e',
        fontWeight: '600',
        fontFamily: `'Montserrat', sans-serif`
    },
    side_panel_modal_main: {
        flex:'1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ['@media(max-width: 1024px)']: {
            width: '100%',
            marginBottom: '50px',
         },
    }
}))

const SidePanel = (props) => {
    const classes = useStyles(props)
    const [state, setState] = useState({})
    const handleRedirect = (id) => {
        if(id === 'category'){
            setState({
                ...state,
                redirect: true,
                path: '/AdminPanelCategory'
            })
        } 
        if(id === 'subcategory'){
            setState({
                ...state,
                redirect: true,
                path: '/AdminPanelSubCategory'
            })
        }
        if(id === 'workbook'){
            setState({
                ...state,
                redirect: true,
                path: '/AdminWorkbook'
            })
        }  
    }
    if(state.redirect){
        return <Redirect to={state.path} />
    }
    return (
        <div className={classes.side_panel_modal_main}>
        <div className={classes.side_panel_modal}>
        <Typography className={classes.side_panel_heading} variant='h5' component='h5'> Workbooks Admin Panel</Typography>
           <WorkbookCard key={1} text='Workbook Category' active={ props.select === 'add category' && true } onClick={() => handleRedirect('category')} />
           <WorkbookCard key={2} text='Workbook Sub Category' active={ props.select === 'add sub category' && true } onClick={() => handleRedirect('subcategory')} />
           <WorkbookCard key={3} text='Workbooks' active={ props.select === 'workbook' && true } onClick={() => handleRedirect('workbook')}/> 
        </div>
        </div>
    )
}

export default SidePanel