import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'



const useStyles = makeStyles(() => ({
    reviews: {
       width: '100%',
       minHeight: '50vh',
       display: 'flex',
       flexDirection: 'column',
       justifyContent: 'space-around',
       alignItems: 'center'

   } ,
   review_contant: {
      color: 'black',
      fontSize: '110%',
      padding: '0% 5%',
      textAlign: 'center'
   },
   review_client: {
    color: 'black',
    fontSize: '170%',
    fontWeight: '700',
    padding: '0% 5%',
    textAlign: 'center'
   },
   img: {
       height: '20vh',
       zIndex: '1'
   }
}))


const Testimonial = (props) => {
    const classes = useStyles()
    return (
        <div className={classes.reviews}>
           <img className={classes.img} alt='logo' src={props.logo} />
             <Typography variant='p' component='p' gutterButtom className={classes.review_client}>
           {props.client}
          
           </Typography>
           <Typography variant='p' component='p' gutterButtom className={classes.review_contant}>
           {props.contant}
           
           </Typography>
          
        </div>
    )
}

export default Testimonial
