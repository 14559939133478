import React, { useState, useEffect } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import SidePanel from './SidePanel';
import DropDownInput from './DropDownInput';
import DropDownOption from './DropDownOption';
import FilteredOptionContainer from './FilteredOptionContainer';
import FilteredOption from './FilteredOption';
import AddButtonIcon from './AddButtonIcon';
import PanelInput from './PanelInput';
import axios from 'axios';
import { Redirect } from 'react-router';
import withProtection from './withProtection';
const useStyles = makeStyles(() => ({
    admin_panel_category: {
        backgroundImage: `url('/AdminLogin/Asset 3.png')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        ['@media(max-width: 1024px)']: {
            flexDirection: 'column'
        },
    },
    admin_panel_category_main: {
        flex: '1',
        ['@media(max-width: 1024px)']: {
            width: '100%',
            marginBottom: '50px',
        },
    },
    admin_panel_category_heading: {
        color: 'white',
        fontWeight: '300',
        margin: '2% 0%',
        fontFamily: `'Montserrat', sans-serif`
    },
    panel_btn: {
        textAlign: 'right',
        marginRight: '200px',
    },
    admin_panel_category_part: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        ['@media(max-width: 1024px)']: {
            width: '100%',
            alignItems: 'center',
        },
    },
    hide: {
        display: 'none',
    },
    label: {
        color: 'white',
        fontSize: '20px'
    },
    label_input: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '80%'
    },
    icon_button: {
        margin: '1% ',
        backgroundColor: '#ffb210',
        '&:hover': {
            backgroundColor: '#ffb210',
            filter: 'brightness(50%)'
        },
    },
    icon_button_save: {
        margin: '1% ',
        backgroundColor: '#93d7d8',
        '&:hover': {
            backgroundColor: '#93d7d8',
            filter: 'brightness(50%)'
        },
    },
    panel_btns: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const AdminPanelSubCategory = (props) => {
    const classes = useStyles(props)
    const [addWorkbook, setAddWorkbook] = useState(false)
    const [list, setList] = useState([])
    const [edit, setEdit] = useState({})
    const [redirect, setRedirect] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')
    const handleAddWorkbook = () => {
        setAddWorkbook(true)
    }
    const handleEditWorkbook = (id, name) => {
        setEdit({
            id,
            category_name: name,
            isEdit: true,
        })
        setAddWorkbook(true)
    }
    const handleCategoryName = (e) => {
        setEdit({
            ...edit,
            category_name: e.target.value
        })
    }
    useEffect(() => {
        axios.get(`https://worksheetsapi.liamcrest.com/api/list_category`)
            .then((res) => {
                const list = res.data.data && res.data.data.length > 0 && res.data.data.map((category) => {
                    return <FilteredOption key={category.id} option={category.category_name} onClick={() => handleEditWorkbook(category.id, category.category_name)} />
                })
                setList(list)
            })
    }, [])
    const handleSave = (e) => {
        if (edit.isEdit) {
            axios.post(`https://worksheetsapi.liamcrest.com/api/update_category`, { category_id: edit.id, category_name: edit.category_name })
                .then((res) => {
                    if (res.data.status == 'success') {
                        setRedirect(true)
                    } else if (res.data.status === 'error') {
                        setError(true)
                        setMessage(res.data.message)
                    }
                })
        } else {
            axios.post(`https://worksheetsapi.liamcrest.com/api/create_category`, { category_name: edit.category_name })
                .then((res) => {
                    if (res.data.status == 'success') {
                        setRedirect(true)
                    } else if (res.data.status === 'error') {
                        setError(true)
                        setMessage(res.data.message)
                    }
                })
        }

    }
    const handleSaveAndAdd = () => {
        if (edit.isEdit) {
            axios.post(`https://worksheetsapi.liamcrest.com/api/update_category`, { category_id: edit.id, category_name: edit.category_name })
                .then((res) => {
                    if (res.data.status == 'success') {
                        setEdit({
                            category_name: ''
                        })
                        setError(false)
                    } else if (res.data.status === 'error') {
                        setError(true)
                        setMessage(res.data.message)
                    }
                })
        } else {
            axios.post(`https://worksheetsapi.liamcrest.com/api/create_category`, { category_name: edit.category_name })
                .then((res) => {
                    if (res.data.status == 'success') {
                        setEdit({
                            category_name: ''
                        })
                        setError(false)
                    } else if (res.data.status === 'error') {
                        setError(true)
                        setMessage(res.data.message)
                    }
                })
        }
    }
    if (redirect) {
        window.location.reload();
    }
    return (
        <div className={classes.admin_panel_category}>
            <SidePanel select='add category' />
            <div className={addWorkbook ? classes.hide : classes.admin_panel_category_main}>
                <div className={classes.panel_btn}>
                    <AddButtonIcon content='ADD WORKBOOK CATEGORY' onClick={handleAddWorkbook} />
                </div>
                <div className={classes.admin_panel_category_part}>
                    <Typography className={classes.admin_panel_category_heading} variant='h5' component='h5' >Select workbook category to change</Typography>
                    <FilteredOptionContainer>
                        {list}
                    </FilteredOptionContainer>
                </div>
            </div>
            <div className={addWorkbook ? classes.admin_panel_category_main : classes.hide}>
                {error && <Alert style={{ width: '50%' }} severity="error">{message}</Alert>}
                <div className={classes.admin_panel_category_part}>
                    <Typography className={classes.admin_panel_category_heading} variant='h5' component='h5' >{edit.isEdit ? 'Edit' : 'Add'} Workbook Category</Typography>
                    <div className={classes.label_input}>
                        <label className={classes.label}>
                            Name:
                        </label>
                        <PanelInput name='category_name' type='text' placeholder='Name' value={edit.category_name} onChange={handleCategoryName} />
                    </div>
                    <div className={classes.panel_btns}>
                        <Button variant="contained" className={classes.icon_button} onClick={handleSaveAndAdd}>Save and add another</Button>
                        <Button variant="contained" className={classes.icon_button_save} onClick={handleSave}>Save</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withProtection(AdminPanelSubCategory)
