import React from 'react';
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    option : {
        padding: "30px",
      }
}))

const DropDownOption = (props) => {
    const classes = useStyles(props)
    return (
        <option className={classes.option} value={props.value} selected={props.selected}>{props.option}</option>
    )
}

export default DropDownOption