import React from 'react'
import { makeStyles, Button, Typography } from '@material-ui/core'
import SocialIcon from './SocialIcon'
import { FaTwitter, FaYoutube, FaLinkedinIn, FaInstagram, FaFacebookF } from 'react-icons/fa'
import FooterUl from './FooterUI'
import FooterAddress from './FooterAddress'
import FooterLi from './FooterLi'
import '../Sass/Footer.scss'
const useStyles = makeStyles(() => ({
    footer_1: {
        minHeight: '90vh',
        width: '100%',
        backgroundColor: '#93d7d8',
        padding: '2.5% 5%',
        boxSizing: 'border-box'
    },
    footer_1_icon_div: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        ['@media(max-width: 1024px)']: {
            display: 'none'
        },
    },
    email_input: {
        width: '150px',
        height: '30px',
        backgroundColor: 'white',
        border: '1px solid white',
        borderRadius: '2px',
        fontSize: '80%',
        margin: '0% 1%'
    },
    footer_1_btns: {
        flex: '1',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    follow_us_div: {
        flex: '1',
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: 'space_around',
        fontWeight: '700'
    },
    footer_1_part: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        ['@media(max-width: 1024px)']: {
            flexDirection: 'column',
        },
    },
    footer_about_us: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    footer_about_us_p: {
        textAlign: 'left',
        fontSize: '100%',
        padding: "0% 20% 0% 0%"
    },
    footer_explore: {
        flex: '1',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    footer_gallery: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    footer_gallery_div: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexWrap: 'wrap'
    },
    subscribe_btn: {
       backgroundColor: '#1ebb90'
    }
}))

const Footer = (props) => {
    const classes = useStyles()
    return (
      <div className={classes.footer_1} id="footer">
        <div className={classes.footer_1_icon_div}>
          <div style={{ flex: "1" }}>
            <img
              className="img"
              src="/Footer/Asset 2.png"
              alt="icon"
              // height="100"
              // width="150"
            />
          </div>
          <div className={classes.footer_1_btns}>
            <input
              name="email"
              type="email"
              placeholder="Your Email"
              className={classes.email_input}
            />
            <Button
              className={classes.subscribe_btn}
              variant="contained"
              color="primary"
            >
              SUBSCRIBE
            </Button>
          </div>
          <div className={classes.follow_us_div}>
            <p>Follow us :</p>
            <SocialIcon
              key={1}
              href="https://www.youtube.com/channel/UCVYVnbFIbWQ4a3MfPyMhQHw"
            >
              <FaYoutube color="#ffb210" size="1.3rem" />
            </SocialIcon>
            <SocialIcon
              key={2}
              href="https://www.linkedin.com/company/liamcrest"
            >
              <FaLinkedinIn color="#ffb210" size="1.3rem" />
            </SocialIcon>
            <SocialIcon
              key={3}
              href="https://www.instagram.com/liam.crest.corp"
            >
              <FaInstagram color="#ffb210" size="1.3rem" />
            </SocialIcon>
            <SocialIcon key={4} href="https://twitter.com/LiamCrestCorp">
              <FaTwitter color="#ffb210" size="1.3rem" />
            </SocialIcon>
            <SocialIcon key={5} href="https://www.facebook.com/liamcrestcorp">
              <FaFacebookF color="#ffb210" size="1.3rem" />
            </SocialIcon>
          </div>
        </div>
        <div className={classes.footer_1_part}>
          <div className={classes.footer_about_us}>
            <FooterUl value="About Us" />
            <Typography
              className={classes.footer_about_us_p}
              variant="p"
              component="p"
              gutterBottom
            >
              At Liam Crest, we're not just an eLearning company; we're your
              partners in unlocking the full potential of online training and
              professional development. Our mission is to revolutionize
              learning, ensuring it's not only engaging and accessible but also
              technically seamless and academically enriching for learners
              worldwide.
            </Typography>
            <FooterAddress
              key={1}
              src="/Footer/Asset 44.png"
              value="888 491 5293"
            />
            <FooterAddress
              key={2}
              src="/Footer/Asset 45.png"
              value="Memphis, TN and Phoenix, AZ"
            />
            <FooterAddress
              key={3}
              src="/Footer/Asset 46.png"
              value="Sales@liamcrest.com"
            />
          </div>
          <div className={classes.footer_explore}>
            <div>
              <FooterUl value="Explore" />
              <FooterLi value="Home" href="/" />
              <FooterLi
                value="About us"
                href="https://www.liamcrest.com/about.html "
              />
              <FooterLi
                value="Our Work"
                href="https://liamcrest.com/Portfolio/"
              />
              <FooterLi value="Contact Us" href="#footer" />
            </div>
            <div>
              <FooterUl value="Quick Links" />
              <FooterLi
                value="Liam Crest eLearning"
                href="https://liamcrest.com/"
              />
              <FooterLi
                value="Liam Crest Development"
                href="https://developer.liamcrest.com/"
              />
              <FooterLi
                value="Liam Crest Productions"
                href="https://production.liamcrest.com/"
              />
              <FooterLi
                value="Liam Crest Design"
                href="https://designers.liamcrest.com/"
              />
            </div>
          </div>
          {/* <div className={classes.footer_gallery}>
                <FooterUl value='Our Gallery' />
                <div className={classes.footer_gallery_div}>
                    <img src='/Footer/Asset 50.png' alt='img'  />
                    <img src='/Footer/Asset 51.png' alt='img'  />
                    <img src='/Footer/Asset 52.png' alt='img'  />
                    <img src='/Footer/Asset 53.png' alt='img'  />
                    <img src='/Footer/Asset 54.png' alt='img'  />
                    <img src='/Footer/Asset 55.png' alt='img'  />
                </div>
                </div> */}
        </div>
      </div>
    );
}

export default Footer
