import React from 'react'

const NavButton = (props) => {
    if(props.active){
        return (
            <a href={props.href}  className='nav_button active font_header' >{props.name}</a>
        )
    } 
    else {
        return (
            <a href={props.href}  className='nav_button font_header' onClick={() => props.onClick(props.name)} >{props.name}</a>
        )
    }
    
}

export default NavButton