import React from 'react';
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    education: {
        paddingTop: '3%',
        backgroundColor: '#1ebb90',
        minHeight: '25vh',
        padding: '3% 15% 2% 15%'
    },
    education_h1: {
        margin: '0',
        padding: '0',
    },
    education_p: {
        fontSize: '20px',
        textAlign: 'center',
        margin: '0% 10%'
    }
}))

const Education = (props) => {
    const classes = useStyles(props)
    return (
      <div className={classes.education}>
        <h1 className={`font-face-gm ${classes.education_h1}`}>
          Liam Crest Education is one key solution for all Schools, Districts
          and Organizations
        </h1>
        <p className={classes.education_p}>
          Our easy-to-use, inclusive, and safe digital Liam Crest Education
          learning program empowers schools and educators to enrich PreK-8
          students.<br></br>
          Add a little rigor and these tools can help undergraduates and
          professionals build vital skills through creative academic workbooks.
        </p>
      </div>
    );
}

export default Education
