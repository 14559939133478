import React from 'react';
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    team: {
        padding: '5% 0%',
        backgroundImage: `url('/Team/Asset 31.png')`,
        backgroundPosition: 'center',
        backgroundRepeat: ' no-repeat',
        backgroundSize: 'cover',
        minHeight: '80vh',
    },
    team_cards: {
        margin: '2% 10%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    team_card_img: {
        margin: '2% 3%',
        '&:hover': {
            transform: 'scale(1.2)',
            transition: "all 0.3s ease-in-out",
        }  
    }
}))

const Library = (props) => {
    const classes = useStyles(props)
    return (
        <div className={classes.team}>
            <h1 className='font-face-gm'> Our Team </h1>
            <div className={classes.team_cards}>
                <img className={classes.team_card_img} src='/Team/Asset 18.png' alt='Team Member' />
                <img className={classes.team_card_img} src='/Team/Asset 17.png' alt='Team Member' />
                <img className={classes.team_card_img} src='/Team/Asset 15.png' alt='Team Member' />
                <img className={classes.team_card_img} src='/Team/Asset 14.png' alt='Team Member' />
                <img className={classes.team_card_img} src='/Team/Asset 19.png' alt='Team Member' />
                <img className={classes.team_card_img} src='/Team/Asset 20.png' alt='Team Member' />
                <img className={classes.team_card_img} src='/Team/Asset 16.png' alt='Team Member' />
            </div>
        </div>
    )
}

export default Library