import React from 'react';
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    belief: {
        padding: '5% 0%',
        backgroundImage: `url('/Belief/Asset 8.png')`,
        backgroundPosition: 'center',
        backgroundRepeat: ' no-repeat',
        backgroundSize: 'cover',
    },
    belief_main: {
        display: 'flex',
        flexDirection: 'row',
        ['@media (max-width:800px)']: {
            flexDirection: 'column',
        },
    },
    belief_part1: {
        margin: '1% 5%',
        boxSizing: 'border-box',
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    belief_part2: {
        margin: '1% 5%',
        boxSizing: 'border-box',
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    para: {
        textAlign: 'justify',
        fontSize: '120%'
    },
    belief_img1: {
        animation: '$MoveUpDown 2s infinite alternate',
        height: '400px',
        ['@media (max-width:1024px)']: {
            height: '300px',
        },
        ['@media (max-width:500px)']: {
            height: '200px',
        }
    },
    "@keyframes MoveUpDown": {
        "0%": {
          transform: "scale(0.9)"
        },
        "100%": {
          transform: "scale(1.2)"
        }
      },
}))

const Belief = (props) => {
    const classes = useStyles(props)
    return (
        <div className={classes.belief}>
            <h1 className='font-face-gm'> Belief Statement </h1>
            <div className={classes.belief_main}>
                <div className={classes.belief_part1}>
                    <p className={classes.para}>
                        We believe that learners should see themselves reflected
                        in the content that they learn. Equity is extremely important
                        and taking the experiences and cultural background of the
                        learner in mind helps provide a holistic educational experience.
                        Learning must be diverse and rich in approach and method.
                        Liam Crest is here to partner with you on this educational
                        journey with a library of educational tools at your disposal.
                    </p>
                </div>
                <div className={classes.belief_part2}>
                <img className={classes.belief_img1} src='/Belief/Asset 7.png'/>
                </div>
            </div>
        </div>
    )
}

export default Belief