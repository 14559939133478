import './App.css';
import Main from './Components/Main';
import Routes from './Components/Routes';

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
