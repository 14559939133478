import React from 'react'
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    footer_li: {
        // width: '100px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',

    },
    footer_li_p: {
        whiteSpace: "nowrap",
        fontSize: '1rem'
    }
}))

const FooterLi = (props) => {
    const classes = useStyles()
    return (
       <a href={props.href} style={{textDecoration: 'none', color: 'black'}}> <div className={classes.footer_li}>
            <img  style={{padding: '0% 10% 0% 0%'}} src='/Footer/Asset 56.png' alt='img' height='20' width='10' />
            <p className={classes.footer_li_p}>
                {props.value}
            </p>
        </div>
        </a>
    )
}

export default FooterLi
