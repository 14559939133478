import React from 'react'
import '../Sass/Header.scss'
const SocialIcon = (props) => {
    return (
       <a href={props.href} className='social_icon_class' style={{textDecoration: "none"}}>
          {props.children}
        </a>
    )
}

export default SocialIcon
