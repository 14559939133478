import React from 'react'
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    footer_address: {
        margin: '3% 0%',
        width: '300px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    footer_address_p: {
        fontWeight: '800',
        overflow: "hidden",
        whiteSpace: "nowrap",
    }
}))

const FooterAddress = (props) => {
    const classes = useStyles()
    return (
        <div className={classes.footer_address}>
            <img style={{margin: '0% 5% 0% 0%'}} src={props.src} alt='img' />
            <p className={classes.footer_address_p}>
                {props.value}
            </p>
        </div>
    )
}

export default FooterAddress
