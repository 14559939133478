import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import '../Sass/Header.scss'
import NavButton from './NavButton'
import SmallHeader from './SmallHeader'
import LoginModal from './LoginModal'
import RegisterModal from './RegisterModel'

const Header = (props) => {
    const [active, setActive] = useState('Home');
    const [toggle, setToggle] = useState(false)
    const handleClick = (name) => {
        setActive(name)
    }
    return (
        <>
            <div className='header'>
                <SmallHeader />
                <div className='nav_bar'>
                   <a href="/"> <img className='img' alt='logo' src='/Header/Asset 63.png'  /></a>
                    <div className='nav_btns'>
                        <NavButton key={1} active={active === 'Home'} name='Home' href='/' onClick={handleClick} />
                        <NavButton key={2} active={active === 'Worksheets'} name='Worksheets' href='https://worksheets.liamcrest.com/login' onClick={handleClick} />
                        <NavButton key={3} active={active === 'Contact'} name='Contact' href='#footer' onClick={handleClick} />
                        <div className='register_btns'>
                            <LoginModal />
                            <RegisterModal />
                        </div>
                    </div>
                    <div className='bars'>
                        {toggle ? <FaTimes color='black' size='2rem' onClick={() => setToggle(!toggle)} /> : <FaBars color='black' size='2rem' onClick={() => setToggle(!toggle)} />}
                    </div>
                </div>
            </div>
            <div className='trans'>
                <div className={toggle ? 'nav_btns_bar' : 'nav_btns_bar_hide'}>
                    <NavButton key={1} active={active === 'Home'} name='Home' href='/' onClick={handleClick} />
                    <NavButton key={2} active={active === 'Worksheets'} name='Worksheets' href='https://worksheets.liamcrest.com/login' onClick={handleClick} />
                    <NavButton key={3} active={active === 'Contact'} name='Contact' href='#footer' onClick={handleClick} />
                    <div className='register_btns'>
                        <LoginModal />
                        <RegisterModal />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header