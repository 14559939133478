import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles, Typography, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab'
import Input from './Input';
import axios from 'axios';
import { Redirect } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    login: {
        color: '#03375e',
        backgroundColor: "white",
        border: "1px solid #03375e",
        padding: "10px 20px",
        borderRadius: "5px",
        margin: "0 5px",
        cursor: "pointer"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#03375e',
        border: '2px solid #03375e',
        borderRadius: '10px',
        boxShadow: theme.shadows[5],
        width: 'clamp(20rem, 30rem, 45rem)',
        minHeight: '85vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ['@media (max-width:500px)']: {
            width: '300px',
        },
        ['@media (max-width:300px)']: {
            width: '200px',
        },
    },
    heading_color: {
        color: 'white',
        margin: '20px 0px',
    },
    login_button: {
        margin: '20px 0px',
        borderRadius: '15px',
        backgroundColor: '#ffb210',
        padding: '1% 8%',
        color: 'black',
        fontWeight: '600',
        fontFamily: `'Montserrat', sans-serif`,
        float: 'right',
        '&:hover': {
            backgroundColor: '#ffb210',
            filter: "brightness(50%)"
        }
    },
    foot: {
        width: '100%',
        minHeight: '35vh',
        backgroundImage: `url('/AdminLogin/Asset 12.png')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: '0% 10%',
        margin: '0',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
    already_member: {
        fontWeight: '600',
        fontSize: '12px',
        textAlgin: 'center',
    },
    already_member_span: {
        color: '#1ebb90'
    },
    foot_auth: {
        textAlgin: 'center',
        fontSize: '10px',
    }
}))

const LoginModal = (props) => {
    const classes = useStyles(props)
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({})
    const [token, setToken] = useState('')
    const handleLogin = () => {
        setState({
            redirect: true
        })
    }

    if (state.redirect) {
        window.location.assign('https://worksheets.liamcrest.com/login');
    } else {
        return (
            <div className={classes.container}>
                <button className={classes.login} onClick={handleLogin}>Log in</button>
            </div>
        )
    }

}

export default LoginModal