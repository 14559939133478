import React from 'react';
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    dash_main: {
        backgroundColor: '#03375e',
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        ['@media (max-width:1024px)']: {
            flexDirection: 'column',
        }
    },
    dash_part1: {
        flex: '1',
        backgroundImage: `url('/Header/Asset 8.png')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dash_part2: {
        flex: '1',
        backgroundImage: `url('/Header/Asset 10.png')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dash_img1: {
        height: '400px',
        ['@media (max-width:1024px)']: {
            height: '300px',
        },
        ['@media (max-width:500px)']: {
            height: '200px',
        }
    },
    dash_img2: {
        height: '300px',
        ['@media (max-width:1024px)']: {
            height: '150px',
        },
        ['@media (max-width:500px)']: {
            height: '100px',
        }
    }
}))

const Dash = (props) => {
    const classes = useStyles(props)
    return (
        <div className={classes.dash_main}>
            <div className={classes.dash_part1}>
                <img className={classes.dash_img1} src='/Header/Asset 1.png' alt='dash' />
            </div>
            <div className={classes.dash_part2}>
                <img className={classes.dash_img2} src='/Header/Asset 12.png' alt='dash' />
            </div>
        </div>
    )
}

export default Dash
