import React from 'react';
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    library: {
        padding: '5% 0%',
        backgroundImage: `url('/Library/Asset 2.png')`,
        backgroundPosition: 'center',
        backgroundRepeat: ' no-repeat',
        backgroundSize: 'cover',
        // minHeight: '80vh',
    },
    library_card: {
        backgroundImage: `url('/Library/Asset 2.png')`,
        backgroundPosition: 'center',
        backgroundRepeat: ' no-repeat',
        backgroundSize: 'contain',
        height: '250px',
        width: '250px',  
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', 
        '&:hover': {
            transform: 'scale(1.2)',
            transition: "all 0.3s ease-in-out",
        }    
    },
    library_card_content: {
        fontSize: '150%',
        fontWeight: '600'
    },
    library_cards: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexWrap: 'wrap'
    }
}))

const Library = (props) => {
    const classes = useStyles(props)
    return (
        <div className={classes.library}>
            <h1 className='font-face-gm'> Our learning library </h1>
            <div className={classes.library_cards}>
           <LibraryCard key={1} src='/Library/Asset 3.png' content='Worksheets'/>
           <LibraryCard key={2} src='/Library/Asset 4.png' content='Activities'/>
           <LibraryCard key={3} src='/Library/Asset 5.png' content='Workbooks'/>
           <LibraryCard key={4} src='/Library/Asset 6.png' content='Games'/>
            </div>
        </div>
    )
}

export default Library

const LibraryCard = (props) => {
    const classes = useStyles(props)
    return (
        <div className={classes.library_card} style={{backgroundImage: `url('${props.src}')`}}>
        <p className={classes.library_card_content}>{props.content}</p>
        </div>
    )
}