import React from 'react';
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    filtered_option_container: {
        margin: '2% 0 0 0',
        width: '350px',
        maxHeight: '30vh',
        overflow: 'hidden',
        overflowY: 'auto',
        "&::-webkit-scrollbar" :{
            width: "10px",
          },
          "&::-webkit-scrollbar-track" :{
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
          },
          
          /* Handle on hover */
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
          ['@media(max-width: 500px)']: {
            width: "250px",
         },
    }
}))

const FilteredOptionContainer = (props) => {
    const classes = useStyles(props)
    return (
        <div className={classes.filtered_option_container}>
            {props.children}
        </div>
    )
}

export default FilteredOptionContainer