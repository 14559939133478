import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import Carousel from 'react-material-ui-carousel'
import {ArrowBack, ArrowForward} from '@material-ui/icons'
import Testimonial from './Testimonial'



const useStyles = makeStyles (()=>({
    main_div: {
        minHeight: '100vh',
        width: '100%',
        backgroundImage: `url('/Testimonials/Asset 40.png')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
      
        alignItems: 'center'
       
    },
    particles: {
        position : "absolute",
        width:"100%",
        height:"100%",
        
        zIndex : '1'
    },
    testimonial_heading: {
        fontSize: '250%',
        color: 'white',
        fontFamily: "Chocolate",
        fontWeight: '700',
        marginTop: '3%',
        marginBottom:'8%'

    },
    yellow_bg: {
        minHeight: '55vh',
        width: '60%',
        borderRadius: '20px',
        backgroundColor: '#FFB20F',
        zIndex: '1',
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    carousel: {
       marginTop:'-12%',
        width: "75%",
        ['@media(max-width: 600px)']: {
            width: '100%'
        },
       }

}))
const Testimonials = () => {
    const classes = useStyles()
    return(
        <div className={classes.main_div}>
        <Typography className={classes.testimonial_heading} variant='h1' component='h1' gutterBottom>
         Testimonial
         </Typography>
       <div className={classes.yellow_bg}>
           <Carousel 
            className={classes.carousel}
            navButtonsAlwaysInvisible={true}
            >
            {/* <Testimonial logo='/Testimonials/2.png' contant='Working with Liam Crest to develop our custom hotspot mapping web platform was a great experience. We came to them with an ambitious idea, and they figured out how to make it a reality. Ashley and her team are extremely responsive to feedback, set clear expectations, meet deadlines, and all-in-all do fabulous work! I highly recommend working with the wonderful folks at Liam Crest for any web-development projects' client='Astraea Howard, Prevention Coordinator, Kentucky Association of Sexual Assault Programs, Inc.'/> */}
            <Testimonial logo='/Testimonials/1.png' contant='Liam Crest enabled us to set the bar high and place our school as one of the top destinations in the region for high school students seeking a rigorous, challenging and rewarding environment, through enhancing our high school curriculum, designing college prep workshops, and creating guidelines for accountability, evaluation, and standardization.' client='CALP International-Sub Administrative Director'/>
            </Carousel>
       </div>

        </div>
    )
}

export default Testimonials