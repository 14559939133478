import React from 'react';
import { makeStyles } from '@material-ui/core'
import { FaPlusCircle } from 'react-icons/fa';
const useStyles = makeStyles(() => ({
    add_button: {
    marginBottom: '1%',
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '0px 0px 20px 20px',
    '&:hover': {
        backgroundColor: '#ffb210'
    }
    },
    add_button_p:{
    color: '#03375e',
    fontWeight: '600'
    },
    active_btn: {
        backgroundColor: '#ffb210'
    }
}))

const AddButton = (props) => {
    const classes = useStyles(props)
    return (
        <div className={ `${classes.add_button} ${props.active && classes.active_btn}` } onClick={props.onClick} >
            <p className={classes.add_button_p}>Add</p>
            <FaPlusCircle color='#03375e' />
        </div>
    )
}

export default AddButton
