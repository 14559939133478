import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    join: {
        padding: '5% 0%',
        backgroundImage: `url('/Join/2.png')`,
        backgroundPosition: 'center',
        backgroundRepeat: ' no-repeat',
        backgroundSize: 'cover',
    },
    join_main: {
        display: 'flex',
        flexDirection: 'row',
        ['@media (max-width:1024px)']: {
            flexDirection: 'column',
        },
    },
    join_part1: {
        margin: '1% 5%',
        boxSizing: 'border-box',
        flex: '2',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    join_part2: {
        margin: '1% 5%',
        boxSizing: 'border-box',
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ['@media (max-width:1024px)']: {
            display: 'none',
        },
    },
    para: {
        textAlign: 'justify',
        fontSize: '120%'
    },
    join_img1: {
        height: '400px',
        ['@media (max-width:1024px)']: {
            height: '300px',
        },
        ['@media (max-width:500px)']: {
            height: '200px',
        }
    },
    join_img2: {
        height: '300px',
        animation: '$MoveUpDown 2s infinite alternate',
        ['@media (max-width:1024px)']: {
            height: '300px',
        },
        ['@media (max-width:500px)']: {
            height: '200px',
        }
    },
    get_btn: {
        backgroundColor: '#ffb210',
        borderRadius: '20px',
        fontWeight: '800',
        '&:hover': {
            backgroundColor: '#ffb210',
            filter: 'brightness(50%)'
        }
    },
    "@keyframes MoveUpDown": {
        "0%": {
            transform: "scale(0.9)"
        },
        "100%": {
            transform: "scale(1.2)"
        }
    },
}))

const Join = (props) => {
    const [redirect, setRedirect] = useState(false)
    const classes = useStyles(props)
    const handleClick = () => {
        setRedirect(true)
    }
    if (redirect) {
        window.location.assign('https://worksheets.liamcrest.com/register');
    } else {
        return (
            <div className={classes.join}>
                <div className={classes.join_main}>
                    <div className={classes.join_part1}>
                        <img className={classes.join_img1} src='/Join/Asset 13.png' />
                    </div>
                    <div className={classes.join_part2}>
                        <img className={classes.join_img2} src='/Join/Asset 14.png' />
                    </div>
                </div>
                <Button variant='filled' className={classes.get_btn} onClick={handleClick}>GET STARTED NOW!</Button>
            </div>
        )
    }
}

export default Join