import React from 'react';
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    panel_textarea: {
     width: '350px',
     padding: '1%',
     fontSize: '20px',
     backgroundColor: 'white',
     border: '2px solid white',
     borderRadius: '5px',
     minHeight: '150px'
    }
}))

const PanelTextarea = (props) => {
    const classes = useStyles(props)
    return (
        <textarea className={classes.panel_textarea} name={props.name} type={props.type} placeholder={props.placeholder} value={props.value} onChange={props.onChange} />
    )
}

export default PanelTextarea