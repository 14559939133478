import React, { useState } from 'react';
import { Button, makeStyles, Typography, } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Redirect } from 'react-router-dom'
import Input from './Input';
import axios from 'axios';
const useStyles = makeStyles(() => ({
    admin_login: {
        fontFamily: `'Montserrat', sans-serif`,
        backgroundImage: `url('/AdminLogin/Asset 3.png')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: ' center',
        alignItems: 'center'
    },
    admin_login_modal: {
        backgroundColor: 'rgba(255, 255, 255, .3)',
        padding: '5% 0%',
        height: '50vh',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        ['@media (max-width:500px)']: {
            width: '80%',
        }

    },
    admin_login_modal_heading: {
        color: 'white',
        fontFamily: `'Montserrat', sans-serif`
    },
    login_button: {
        borderRadius: '15px',
        backgroundColor: '#ffb210',
        padding: '1% 8%',
        color: 'black',
        fontWeight: '600',
        fontFamily: `'Montserrat', sans-serif`,
        '&:hover': {
            backgroundColor: '#ffb210',
            filter: "brightness(50%)"
        }
    }
}))

const AdminLogin = (props) => {
    const classes = useStyles(props)
    const [state, setState] = useState({})
    const [error, setError] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const handleSubmit = async (e) => {
        try {
            const data = await axios.post(`https://worksheetsapi.liamcrest.com/api/admin_auth`, state)
            if (data.data.status == 'success') {
                localStorage.setItem('token', data.data.token)
                setRedirect(true)
            } else if (data.data.status == 'error') {
                setError(true)
            }
        }
        catch (err) {
            setError(true)
        }

    }
    if(redirect){
        return <Redirect to='/AdminPanel' />
    }
    return (
        <div className={classes.admin_login}>
            <div className={classes.admin_login_modal}>
                <Typography variant='h4' component='h4' className={classes.admin_login_modal_heading}>Admin Login</Typography>
                {error && <Alert severity="error">Something went wrong — check it out!</Alert>}
                <Input name='user_name' type='text' placeholder='Email Address / Username' onChange={handleChange} src='/AdminLogin/Asset 6.png' />
                <Input name='password' type='password' placeholder='Password' onChange={handleChange} src='/AdminLogin/Asset 7.png' />
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.login_button}
                    onClick={handleSubmit}
                >Login</Button>
            </div>
        </div>
    )
}

export default AdminLogin