import React from 'react';
import { makeStyles, Button } from '@material-ui/core'
import { FaPlusCircle } from 'react-icons/fa';
const useStyles = makeStyles(() => ({
icon_button: {
    backgroundColor: '#ffb210',
    '&:hover' : {
    backgroundColor: '#ffb210',
    filter: 'brightness(50%)'
    },
}
}))

const AddButtonIcon = (props) => {
    const classes = useStyles(props)
    return (
        <Button variant="contained" className={classes.icon_button} endIcon={<FaPlusCircle color='#03375e' />} onClick={props.onClick}>
        {props.content}
        </Button>
    )
}

export default AddButtonIcon