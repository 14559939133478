import React, { useState, useEffect } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SidePanel from './SidePanel';
import DropDownInput from './DropDownInput';
import DropDownOption from './DropDownOption';
import FilteredOptionContainer from './FilteredOptionContainer';
import FilteredOption from './FilteredOption';
import AddButtonIcon from './AddButtonIcon';
import PanelInput from './PanelInput';
import axios from 'axios';
import withProtection from './withProtection';
const useStyles = makeStyles(() => ({
    admin_panel_category: {
        fontFamily: `'Montserrat', sans-serif`,
        backgroundImage: `url('/AdminLogin/Asset 3.png')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        ['@media(max-width: 1024px)']: {
            flexDirection: 'column'
        },
    },
    admin_panel_category_main: {
        flex: '1',
        ['@media(max-width: 1024px)']: {
            width: '100%',
            marginBottom: '50px',
        },
    },
    admin_panel_category_heading: {
        color: 'white',
        fontWeight: '300',
        margin: '2% 0%',
        fontFamily: `'Montserrat', sans-serif`,
    },
    panel_btn: {
        fontFamily: `'Montserrat', sans-serif`,
        textAlign: 'right',
        marginRight: '200px',
    },
    admin_panel_category_part: {
        fontFamily: `'Montserrat', sans-serif`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        ['@media(max-width: 1024px)']: {
            width: '100%',
            alignItems: 'center',
        },
    },
    hide: {
        display: 'none',
    },
    label: {
        color: 'white',
        fontSize: '20px',
        fontFamily: `'Montserrat', sans-serif`,
    },
    label_input: {
        fontFamily: `'Montserrat', sans-serif`,
        margin: '2% 0%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '80%'
    },
    icon_button: {
        margin: '1% ',
        backgroundColor: '#ffb210',
        '&:hover': {
            backgroundColor: '#ffb210',
            filter: 'brightness(50%)'
        },
    },
    icon_button_save: {
        margin: '1% ',
        backgroundColor: '#93d7d8',
        '&:hover': {
            backgroundColor: '#93d7d8',
            filter: 'brightness(50%)'
        },
    },
    panel_btns: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const AdminPanelCategory = (props) => {
    const classes = useStyles(props)
    const [addWorkbook, setAddWorkbook] = useState(false)
    const [dropDown, setDropDown] = useState([])
    const [filtered, setFiltered] = useState([])
    const [state, setState] = useState({
        category_id: 'all',
    })
    const handleAddWorkbook = () => {
        setState({
            category_id: 'all'
        })
        setAddWorkbook(true)
    }
    const handleFiltered = (id, name) => {
        setState({ ...state, subcategory_id: id, subcategory_name: name, isEdit: true })
        setAddWorkbook(true)
    }
    useEffect(() => {
        axios.get(`https://worksheetsapi.liamcrest.com/api/list_category`)
            .then((res) => {
                let DropDown = res.data.data && res.data.data.length > 0 && res.data.data.map((category) => {
                    return <DropDownOption key={category.id} value={category.id} option={category.category_name} selected={category.id == state.category_id} />
                })
                setDropDown(DropDown)
            })
    }, [])
    useEffect(() => {
        if (state.category_id == 'all') {
            axios.get(`https://worksheetsapi.liamcrest.com/api/list_subcategory`)
                .then((res) => {
                    if (res.data.status == 'success') {
                        let Filtered = res.data.data && res.data.data.length > 0 && res.data.data.map((option) => {
                            return <FilteredOption key={option.id} option={option.subcategory_name} onClick={() => handleFiltered(option.id, option.subcategory_name)} />
                        })
                        setFiltered(Filtered)
                    }
                })
        } else {
            axios.post(`https://worksheetsapi.liamcrest.com/api/listc_subcategory`, { category_id: state.category_id })
                .then((res) => {
                    if (res.data.status == 'success') {
                        let Filtered = res.data.data && res.data.data.length > 0 && res.data.data.map((option) => {
                            return <FilteredOption key={option.id} option={option.subcategory_name} onClick={() => handleFiltered(option.id, option.subcategory_name)} />
                        })
                        setFiltered(Filtered)
                    } else {
                        setFiltered([])
                    }
                })
        }

    }, [state.category_id])
    const handleDropDown = (e) => {
        setState({
            ...state,
            category_id: e.target.value,
        })
    }
    const handleSave = (e) => {
        if (state.category_id !== 'all') {
            if (state.isEdit) {
                axios.post(`https://worksheetsapi.liamcrest.com/api/update_subcategory`, { category_id: state.category_id, subcategory_id: state.subcategory_id, subcategory_name: state.subcategory_name })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            window.location.reload();
                        } else if (res.data.status == 'error') {
                            setState({
                                ...state,
                                error: true,
                                errorMessage: res.data.message,
                            })
                        }
                    })
            } else {
                axios.post(`https://worksheetsapi.liamcrest.com/api/create_subcategory`, { category_id: state.category_id, subcategory_name: state.subcategory_name })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            window.location.reload();
                        } else if (res.data.status == 'error') {
                            setState({
                                ...state,
                                error: true,
                                errorMessage: res.data.message,
                            })
                        }
                    })
            }
        } else if (state.category_id == 'all') {
            setState({
                ...state,
                error: true,
                errorMessage: 'Please select category',
            })
        }
    }
    const handleSaveAndAdd = (e) => {
        if (state.category_id !== 'all') {
            if (state.isEdit) {
                axios.post(`https://worksheetsapi.liamcrest.com/api/update_subcategory`, { category_id: state.category_id, subcategory_id: state.subcategory_id, subcategory_name: state.subcategory_name })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            setState({
                                category_id: 'all',
                                subcategory_name: '',
                            })
                        } else if (res.data.status == 'error') {
                            setState({
                                ...state,
                                error: true,
                                errorMessage: res.data.message,
                            })
                        }
                    })
            } else {
                axios.post(`https://worksheetsapi.liamcrest.com/api/create_subcategory`, { category_id: state.category_id, subcategory_name: state.subcategory_name })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            setState({
                                category_id: 'all',
                                subcategory_name: '',
                            })
                        } else if (res.data.status == 'error') {
                            setState({
                                ...state,
                                error: true,
                                errorMessage: res.data.message,
                            })
                        }
                    })
            }
        } else if (state.category_id == 'all') {
            setState({
                ...state,
                error: true,
                errorMessage: 'Please select category',
            })
        }
    }
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        })
    }
    return (
        <div className={classes.admin_panel_category}>
            <SidePanel select='add sub category' />
            <div className={addWorkbook ? classes.hide : classes.admin_panel_category_main}>
                <div className={classes.panel_btn}>
                    <AddButtonIcon content='ADD WORKBOOK SUB CATEGORY' onClick={handleAddWorkbook} />
                </div>
                <div className={classes.admin_panel_category_part}>
                    <Typography className={classes.admin_panel_category_heading} variant='h5' component='h5' >Select category to change sub category</Typography>
                    <DropDownInput name='category' placeholder='Select' handleSelect={handleDropDown}>
                        <DropDownOption value='all' option='All' />
                        {dropDown}
                    </DropDownInput>
                    <FilteredOptionContainer>
                        {filtered}
                    </FilteredOptionContainer>
                </div>
            </div>
            <div className={addWorkbook ? classes.admin_panel_category_main : classes.hide}>
                {state.error && <Alert style={{ width: '50%' }} severity="error">{state.errorMessage}</Alert>}
                <div className={classes.admin_panel_category_part}>
                    <Typography className={classes.admin_panel_category_heading} variant='h5' component='h5' >Add Workbook Sub Category</Typography>
                    <div className={classes.label_input}>
                        <label className={classes.label}>
                            Name:
                        </label>
                        <PanelInput name='subcategory_name' type='text' placeholder='Name' value={state.subcategory_name} onChange={handleChange} />
                    </div>
                    <div className={classes.label_input}>
                        <label className={classes.label}>
                            Category:
                        </label>
                        <DropDownInput name='category' placeholder='Select' value={state.category_id} handleSelect={handleDropDown} >
                            <DropDownOption value='all' option='All' />
                            {dropDown}
                        </DropDownInput>
                    </div>
                    <div className={classes.panel_btns}>
                        <Button variant="contained" className={classes.icon_button} onClick={handleSaveAndAdd}>Save and add another</Button>
                        <Button variant="contained" className={classes.icon_button_save} onClick={handleSave}>Save</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withProtection(AdminPanelCategory)
