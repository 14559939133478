import React from 'react'
import About from './About'
import Belief from './Belief'
import Dash from './Dash'
import Education from './Education'
import Header from './Header'
import Join from './Join'
import Library from './Library'
import Services from './Services'
import Team from './Team'
import Testimonials from './Testimonials'
import Footer from './Footer'

const Main = () => {
    return (
        <>
        <Header />
        <Dash />
        <About />
        <Library />
        <Belief />
        <Education />
        <Services />
        <Join />
        <Testimonials />
        <Footer />
        </>
    )
}

export default Main
