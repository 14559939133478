import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core'
import WorkbookCard from './WorkbookCard';
import { Redirect } from 'react-router';
import withProtection from './withProtection';
const useStyles = makeStyles(() => ({
    admin_panel: {
        fontFamily: `'Montserrat', sans-serif`,
        backgroundImage: `url('/AdminLogin/Asset 3.png')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: ' center',
        alignItems: 'center'
    },
    admin_panel_modal: {
        fontFamily: `'Montserrat', sans-serif`,
        backgroundColor: 'rgba(255, 255, 255, .3)',
        padding: '5% 2%',
        height: '50vh',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        ['@media (max-width:500px)']: {
            width: '80%',
            height: '80vh',
        }

    },
    admin_panel_heading: {
        color: '#03375e',
        fontWeight: '600',
        fontFamily: `'Montserrat', sans-serif`,
    },
    admin_panel_cards: {
        fontFamily: `'Montserrat', sans-serif`,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexWrap: 'wrap'

    }
}))

const AdminPanel = (props) => {
    const classes = useStyles(props)
    const [path, setPath] = useState('')
    const [redirect, setRedirect] = useState(false)
    const handleClick = (name) => {
        if (name === 'Workbook Category') {
            setPath('/AdminPanelCategory')
            setRedirect(true)
        }
        if (name === 'Workbook Sub Category') {
            setPath('/AdminPanelSubCategory')
            setRedirect(true)
        }
        if (name === 'Workbooks') {
            setPath('/Workbooks')
            setRedirect(true)
        }
    }
    if(redirect){
        return <Redirect to={path} />
    }
    return (
        <div className={classes.admin_panel}>
            <div className={classes.admin_panel_modal}>
                <Typography className={classes.admin_panel_heading} variant='h4' component='h4'> Workbooks Admin Panel</Typography>
                <div className={classes.admin_panel_cards}>
                    <WorkbookCard key={1} text='Workbook Category' onClick={() => handleClick('Workbook Category')} />
                    <WorkbookCard key={2} text='Workbook Sub Category' onClick={() => handleClick('Workbook Sub Category')} />
                    <WorkbookCard key={3} text='Workbooks' onClick={() => handleClick('Workbooks')} />
                </div>
            </div>
        </div>
    )
}

export default withProtection(AdminPanel)