import React, { useState, useEffect } from 'react';
import { Button, makeStyles, Typography, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import SidePanel from './SidePanel';
import DropDownInput from './DropDownInput';
import DropDownOption from './DropDownOption';
import FilteredOptionContainer from './FilteredOptionContainer';
import FilteredOption from './FilteredOption';
import AddButtonIcon from './AddButtonIcon';
import PanelInputFile from './PanelInputFile';
import PanelInput from './PanelInput';
import PanelTextarea from './PanelTextarea';
import axios from 'axios';
import withProtection from './withProtection';
const useStyles = makeStyles(() => ({
    admin_panel_category: {
        fontFamily: `'Montserrat', sans-serif`,
        backgroundImage: `url('/AdminLogin/Asset 3.png')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        ['@media(max-width: 1024px)']: {
            flexDirection: 'column'
        },
    },
    admin_panel_category_main: {
        flex: '1',
        ['@media(max-width: 1024px)']: {
            width: '100%',
            marginBottom: '50px',
        },
    },
    admin_panel_category_heading: {
        color: 'white',
        fontWeight: '300',
        margin: '2% 0%',
        fontFamily: `'Montserrat', sans-serif`,
    },
    panel_btn: {
        fontFamily: `'Montserrat', sans-serif`,
        textAlign: 'right',
        marginRight: '200px',
    },
    admin_panel_category_part: {
        fontFamily: `'Montserrat', sans-serif`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        ['@media(max-width: 1024px)']: {
            width: '100%',
            alignItems: 'center',
        },
    },
    hide: {
        display: 'none',
    },
    label: {
        color: 'white',
        fontSize: '20px',
        fontFamily: `'Montserrat', sans-serif`,
    },
    label_input: {
        fontFamily: `'Montserrat', sans-serif`,
        margin: '2% 0%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '80%'
    },
    icon_button: {
        margin: '1% ',
        backgroundColor: '#ffb210',
        '&:hover': {
            backgroundColor: '#ffb210',
            filter: 'brightness(50%)'
        },
    },
    icon_button_save: {
        margin: '1% ',
        backgroundColor: '#93d7d8',
        '&:hover': {
            backgroundColor: '#93d7d8',
            filter: 'brightness(50%)'
        },
    },
    panel_btns: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const AddWorkbook = (props) => {
    const classes = useStyles(props)
    const [state, setState] = useState({ subcategory_id: 'all' })
    const [dropDown, setDropDown] = useState([])
    const [filtered, setFiltered] = useState([])
    const handleDropDown = (e) => {
        setState({
            ...state,
            subcategory_id: e.target.value,
        })
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }
    const [addWorkbook, setAddWorkbook] = useState(false)
    const handleAddWorkbook = () => {
        setState({ subcategory_id: 'all' })
        setAddWorkbook(true)
    }
    const handleFiltered = (id, data) => {
        setState({ ...state, isEdit: true, ...data })
        setAddWorkbook(true)
    }
    useEffect(() => {
        axios.get(`https://worksheetsapi.liamcrest.com/api/list_subcategory`)
            .then((res) => {
                let DropDown = res.data.data && res.data.data.length > 0 && res.data.data.map((subcategory) => {
                    return <DropDownOption key={subcategory.id} value={subcategory.id} option={subcategory.subcategory_name} selected={subcategory.id == state.subcategory_id} />
                })
                setDropDown(DropDown)
            })
    }, [])
    useEffect(() => {
        if (state.subcategory_id == 'all') {
            axios.get(`https://worksheetsapi.liamcrest.com/api/list_worksheets`)
                .then((res) => {
                    if (res.data.status == 'success') {
                        let Filtered = res.data.data && res.data.data.length > 0 && res.data.data.map((option) => {
                            return <FilteredOption key={option.id} option={option.worksheet_name} onClick={() => handleFiltered(option.id, option)} />
                        })
                        setFiltered(Filtered)
                    }
                })
        } else {
            axios.post(`https://worksheetsapi.liamcrest.com/api/listc_worksheets`, { subcategory_id: state.subcategory_id })
                .then((res) => {
                    if (res.data.status == 'success') {
                        let Filtered = res.data.data && res.data.data.length > 0 && res.data.data.map((option) => {
                            return <FilteredOption key={option.id} option={option.worksheet_name} onClick={() => handleFiltered(option.id, option)} />
                        })
                        setFiltered(Filtered)
                    } else {
                        setFiltered([])
                    }
                })
        }

    }, [state.subcategory_id])
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        })
    }
    const handleUploadImage = async (e) => {
        setState({ ...state, loading: true })
        const file = await convertBase64(e.target.files[0])
        const data = await axios.post(`https://worksheetsapi.liamcrest.com/api/upload_image`, { title_image: file })
        if (data.data.status === 'success') {
            setState({ ...state, loading: false, worksheet_image_url: data.data.data })
        }
    }
    const handleUploadPdf = async (e) => {
        setState({ ...state, loading: true })
        const file = await convertBase64(e.target.files[0])
        const data = await axios.post(`https://worksheetsapi.liamcrest.com/api/upload_file`, { file: file })
        if (data.data.status === 'success') {
            setState({ ...state, loading: false, worksheet_pdf: data.data.data })
        }
    }
    const handleUploadPdfAnswer = async (e) => {
        setState({ ...state, loading: true })
        const file = await convertBase64(e.target.files[0])
        const data = await axios.post(`https://worksheetsapi.liamcrest.com/api/upload_file`, { file: file })
        if (data.data.status === 'success') {
            setState({ ...state, loading: false, worksheet_answer_pdf: data.data.data })
        }
    }
    const handleSave = (e) => {
        if (state.subcategory_id !== 'all') {
            if (state.isEdit) {
                axios.post(`https://worksheetsapi.liamcrest.com/api/update_worksheet`, { ...state, worksheet_id: state.id, worksheet_title_image: state.worksheet_image_url })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            window.location.reload();
                        } else if (res.data.status == 'error') {
                            setState({
                                ...state,
                                error: true,
                                errorMessage: res.data.message,
                            })
                        }
                    })
            } else {
                axios.post(`https://worksheetsapi.liamcrest.com/api/create_worksheet`, { ...state, worksheet_title_image: state.worksheet_image_url })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            window.location.reload();
                        } else if (res.data.status == 'error') {
                            setState({
                                ...state,
                                error: true,
                                errorMessage: res.data.message,
                            })
                        }
                    })
            }
        } else if (state.category_id == 'all') {
            setState({
                ...state,
                error: true,
                errorMessage: 'Please select category',
            })
        }
    }
    const handleSaveAndAdd = (e) => {
        if (state.subcategory_id !== 'all') {
            if (state.isEdit) {
                axios.post(`https://worksheetsapi.liamcrest.com/api/update_worksheet`, { ...state, worksheet_id: state.id, worksheet_title_image: state.worksheet_image_url })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            setState({subcategory_id: 'all', worksheet_name: '', worksheet_description: ''})
                        } else if (res.data.status == 'error') {
                            setState({
                                ...state,
                                error: true,
                                errorMessage: res.data.message,
                            })
                        }
                    })
            } else {
                axios.post(`https://worksheetsapi.liamcrest.com/api/create_worksheet`, { ...state, worksheet_title_image: state.worksheet_image_url })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            setState({subcategory_id: 'all', worksheet_name: '', worksheet_description: ''})
                        } else if (res.data.status == 'error') {
                            setState({
                                ...state,
                                error: true,
                                errorMessage: res.data.message,
                            })
                        }
                    })
            }
        } else if (state.subcategory_id == 'all') {
            setState({
                ...state,
                error: true,
                errorMessage: 'Please select category',
            })
        }
    }
    if (state.loading) {
        return (
            <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color='primary' />
            </div>
        )
    }
    return (
        <div className={classes.admin_panel_category}>
            <SidePanel select='workbook' />
            <div className={addWorkbook ? classes.hide : classes.admin_panel_category_main}>
                <div className={classes.panel_btn}>
                    <AddButtonIcon content='ADD WORKBOOK' onClick={handleAddWorkbook} />
                </div>
                <div className={classes.admin_panel_category_part}>
                    <Typography className={classes.admin_panel_category_heading} variant='h5' component='h5' >Select category to change sub category</Typography>
                    <DropDownInput name='category' placeholder='Select' handleSelect={handleDropDown}>
                        <DropDownOption value='all' option='All' />
                        {dropDown}
                    </DropDownInput>
                    <FilteredOptionContainer>
                        {filtered}
                    </FilteredOptionContainer>
                </div>
            </div>
            <div className={addWorkbook ? classes.admin_panel_category_main : classes.hide}>
            {state.error && <Alert style={{ width: '50%' }} severity="error">{state.errorMessage}</Alert>}
                <div className={classes.admin_panel_category_part}>
                    <Typography className={classes.admin_panel_category_heading} variant='h5' component='h5' >Add Workbook</Typography>
                    <div className={classes.label_input}>
                        <label className={classes.label}>
                            Name:
                        </label>
                        <PanelInput name='worksheet_name' type='text' placeholder='Name' value={state.worksheet_name} onChange={handleChange} />
                    </div>
                    <div className={classes.label_input}>
                        <label className={classes.label}>
                            Image:
                        </label>
                        <PanelInputFile name='image' accept="image/png, image/gif, image/jpeg" url={state.worksheet_image_url} onChange={handleUploadImage} />
                    </div>
                    <div className={classes.label_input}>
                        <label className={classes.label}>
                            Pdf:
                        </label>
                        <PanelInputFile name='pdf' accept=".pdf" url={state.worksheet_pdf} onChange={handleUploadPdf} />
                    </div>
                    <div className={classes.label_input}>
                        <label className={classes.label}>
                            Description:
                        </label>
                        <PanelTextarea name='worksheet_description' type='text' placeholder='Description' value={state.worksheet_description} onChange={handleChange} />
                    </div>
                    <div className={classes.label_input}>
                        <label className={classes.label}>
                            Sub Category:
                        </label>
                        <DropDownInput name='category' placeholder='Select' value={state.subcategory_id} handleSelect={handleDropDown}>
                            <DropDownOption value='all' option='All' selected={true} />
                            {dropDown}
                        </DropDownInput>
                    </div>
                    <Typography className={classes.admin_panel_category_heading} variant='h5' component='h5' >Workbook Answer</Typography>
                    <div className={classes.label_input}>
                        <label className={classes.label}>
                            Pdf:
                        </label>
                        <PanelInputFile name='answer_pdf' accept=".pdf" url={state.worksheet_answer_pdf} onChange={handleUploadPdfAnswer} />
                    </div>
                    <div className={classes.label_input}>
                        <label className={classes.label}>
                            Password:
                        </label>
                        <PanelInput name='worksheet_answer_password' type='text' placeholder='Password' value={state.worksheet_answer_password} onChange={handleChange} />
                    </div>
                    <div className={classes.panel_btns}>
                        <Button variant="contained" className={classes.icon_button} onClick={handleSaveAndAdd}>Save and add another</Button>
                        <Button variant="contained" className={classes.icon_button_save} onClick={handleSave}>Save</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withProtection(AddWorkbook)
