import React from 'react';
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    about: {
        padding: '3% 0px',
        backgroundColor: '#ffb210',
        
    },
    about_h1: {
        margin: '0',
        padding: '0',
    },
    about_p: {
        fontSize: '20px',
        textAlign: 'center',
        margin: '0% 10%'
    }
}))

const About = (props) => {
    const classes = useStyles(props)
    return (
      <div className={classes.about} id="aboutUs">
        {/* <p className={classes.about_p}>Liam Crest strives to make learning engaging, accessible, technologically sound, and academically
                rigorous for learners around the world. As a company, we are founded on four main principle that come
                together to make us the best</p> */}
        <p className={classes.about_p}>
          Subscribe and get access to <strong>free worksheets!</strong>{" "}
          <br></br> Just click below and take advantage of our worksheet
          catalog. <br></br>Do have ideas for your learners? Let’s explore cool
          concepts to make learning fun. Your options are limitless. We can
          design it for you. Use it for teaching and training all age levels.
        </p>
      </div>
    );
}

export default About
